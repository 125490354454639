import React, { useState, useEffect, useContext } from "react";
import {
    Col,
    Form,
    Row,
    Card
} from "react-bootstrap";
import axios from "axios";
import DropzoneComponent from "../Dropzone.js";
import { domainConfig } from "../../config.js";
import NotyfContext from "../../contexts/NotyfContext";
import PopoverPicker from "../../components/PopoverPicker.js";
import Select from 'react-select';

const presetStyles = [
    { value: "ANIME", label: "ANIME" },
    { value: "BOKEH", label: "BOKEH" },
    { value: "CINEMATIC", label: "CINEMATIC" },
    { value: "CINEMATIC_CLOSEUP", label: "CINEMATIC_CLOSEUP" },
    { value: "CREATIVE", label: "CREATIVE" },
    { value: "DYNAMIC", label: "DYNAMIC" },
    { value: "ENVIRONMENT", label: "ENVIRONMENT" },
    { value: "FASHION", label: "FASHION" },
    { value: "FILM", label: "FILM" },
    { value: "FOOD", label: "FOOD" },
    { value: "GENERAL", label: "GENERAL" },
    { value: "HDR", label: "HDR" },
    { value: "ILLUSTRATION", label: "ILLUSTRATION" },
    { value: "LEONARDO", label: "LEONARDO" },
    { value: "LONG_EXPOSURE", label: "LONG_EXPOSURE" },
    { value: "MACRO", label: "MACRO" },
    { value: "MINIMALISTIC", label: "MINIMALISTIC" },
    { value: "MONOCHROME", label: "MONOCHROME" },
    { value: "MOODY", label: "MOODY" },
    { value: "NONE", label: "NONE" },
    { value: "NEUTRAL", label: "NEUTRAL" },
    { value: "PHOTOGRAPHY", label: "PHOTOGRAPHY" },
    { value: "PORTRAIT", label: "PORTRAIT" },
    { value: "RAYTRACED", label: "RAYTRACED" },
    { value: "RENDER_3D", label: "RENDER_3D" },
    { value: "RETRO", label: "RETRO" },
    { value: "SKETCH_BW", label: "SKETCH_BW" },
    { value: "SKETCH_COLOR", label: "SKETCH_COLOR" },
    { value: "STOCK_PHOTO", label: "STOCK_PHOTO" },
    { value: "VIBRANT", label: "VIBRANT" },
    { value: "UNPROCESSED", label: "UNPROCESSED" }
];

const Photobooth = ({
    handleChange,
    values,
    setFieldValue,
    errors,
    touched,
    handlePreviewSuccess,
    handlePreviewError,
}) => {
    const notyf = useContext(NotyfContext);
    const [props, setProps] = useState({
        backgroundColor: values.json.apresentation?.textSide?.backgroundColor,
        textColor: values.json.apresentation?.textSide?.textColor
    });
    const [leonardoaiPlataforms, setleonardoaiPlatforms] = useState([])

    useEffect(() => {
        axios
            .get(`/api/leonardoai/platforms`)
            .then(function (response) {
                console.log(response.data.message)
                if (response.data.message.custom_models) {
                    let filteredData = response.data.message.custom_models.filter(item => item.featured === true);
                    filteredData.forEach(element => {
                        element.value = element.id
                        element.label = element.name
                    });
                    setleonardoaiPlatforms(filteredData)
                }
            })
            .catch(function (error) {
                notyf.open({
                    type: "warning",
                    message: "Houve um problema ao buscar as plataformas da Leonardo AI",
                    ripple: true,
                    dismissible: true,
                });
            });
    }, []);

    useEffect(() => {
        notyf.open({
            type: "warning",
            message: "Esse aplicativo possui definições adicionais no modo do apresentador",
            ripple: true,
            dismissible: true,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const customOption = (props) => {
        const { data, innerRef, innerProps } = props;
        return (
            <div
                ref={innerRef}
                {...innerProps}
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    padding: '10px',
                    cursor: 'pointer',
                }}
            >
                <img
                    src={data.generated_image === null ? "https://via.placeholder.com/50" : data.generated_image.url}
                    alt={data.name}
                    style={{ width: 50, height: 50, borderRadius: '50%', marginRight: 10 }}
                />
                <div className="vertically-centered">
                    <p className="mb-0 w-100">{data.name}</p>
                    <p className="mb-0"><small>{data.description}</small></p>
                </div>
            </div>
        );
    };
    console.log(values)
    console.log(errors)
    return (
        <>
            <Form.Group as={Col} className="textHeaderAplicativo mb-3">
                <h5 className="card-title mb-0">Definições</h5>
            </Form.Group>
            <Row className="mx-0 mb-3">
                <Form.Group
                    className="mb-3"
                    as={Col}
                    sm="12"
                    controlId="validationFormik01"
                >
                    <Form.Label>Título</Form.Label>
                    <Form.Control
                        as={'textarea'}
                        name="titulo"
                        value={values.titulo}
                        onChange={handleChange}
                        isInvalid={!!errors.titulo}
                        isValid={touched.titulo && !errors.titulo}
                    />
                    <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        {errors.titulo}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group
                    className="mb-3"
                    as={Col}
                    sm="12"
                    controlId="validationFormik01"
                >
                    <Form.Check
                        checked={values.json.apresentation.configs.showLastUser === true ? true : false}
                        type="checkbox"
                        name="json.apresentation.configs.showLastUser"
                        label="Adicionar botão para mostrar último QRCode gerado"
                        onChange={(e) => {
                            setFieldValue(
                                "json.apresentation.configs.showLastUser",
                                values.json.apresentation.configs.showLastUser === true ? false : true
                            );
                        }}
                    />
                    <Form.Check
                        checked={values.json.apresentation.configs.printPhoto === true ? true : false}
                        type="checkbox"
                        name="json.apresentation.configs.printPhoto"
                        label="Ativar impressão na tela final"
                        onChange={(e) => {
                            setFieldValue(
                                "json.apresentation.configs.printPhoto",
                                values.json.apresentation.configs.printPhoto === true ? false : true
                            );
                        }}
                    />
                </Form.Group>

                <p className="text-start fw-bold mt-3 mb-2">Formulário</p>
                <div className="d-flex text-start">
                    <Form.Group sm={3} className='mb-3'>
                        <Form.Check
                            className="mode"
                            checked={values.json.apresentation.configs.form === false ? true : false}
                            type="checkbox"
                            name={"json.apresentation.configs.form"}
                            onChange={(e) => {
                                setFieldValue(
                                    'json.apresentation.configs.form', false
                                );
                            }}
                            label={`Sem coleta`}
                            isInvalid={!!errors.json?.apresentation?.configs?.form}
                            feedback={errors.json?.apresentation?.configs?.form}
                            feedbackType="invalid">
                        </Form.Check>
                    </Form.Group>
                    <Form.Group sm={3} className='mb-3 ms-3'>
                        <Form.Check
                            className="mode"
                            checked={values.json.apresentation.configs.form === 1 ? true : false}
                            type="checkbox"
                            name={"json.apresentation.configs.form"}
                            onChange={(e) => {
                                setFieldValue(
                                    'json.apresentation.configs.form', 1
                                );
                            }}
                            label={`Antes do QRCode`}
                            isInvalid={!!errors.json?.apresentation?.configs?.form}
                            feedback={errors.json?.apresentation?.configs?.form}
                            feedbackType="invalid">
                        </Form.Check>
                    </Form.Group>
                    <Form.Group sm={3} className='mb-3 ms-3'>
                        <Form.Check
                            className="mode"
                            checked={values.json.apresentation.configs.form === 2 ? true : false}
                            type="checkbox"
                            name={"json.apresentation.configs.form"}
                            onChange={(e) => {
                                setFieldValue(
                                    'json.apresentation.configs.form', 2
                                );
                            }}
                            label={`Após o QRCode`}
                            isInvalid={!!errors.json?.apresentation?.configs?.form}
                            feedback={errors.json?.apresentation?.configs?.form}
                            feedbackType="invalid">
                        </Form.Check>
                    </Form.Group>
                </div>

                <p className="text-start fw-bold mt-3 mb-2">Tipo de mídia</p>
                <div className="d-flex text-start">
                    <Form.Group sm={6} className='mb-3'>
                        <Form.Check
                            className="mode"
                            checked={values.json.apresentation.configs.media === 'image' ? true : false}
                            type="checkbox"
                            name={"json.apresentation.configs.media"}
                            onChange={(e) => {
                                setFieldValue(
                                    'json.apresentation.configs.media', 'image'
                                );
                                setFieldValue(
                                    'json.apresentation.configs.leonardoai.status', false
                                );
                            }}
                            label={`Imagem`}
                            isInvalid={!!errors.json?.apresentation?.configs?.media}
                            feedback={errors.json?.apresentation?.configs?.media}
                            feedbackType="invalid">
                        </Form.Check>
                    </Form.Group>
                    <Form.Group sm={6} className='mb-3 ms-3'>
                        <Form.Check
                            className="mode"
                            checked={values.json.apresentation.configs.media === 'image-ai' ? true : false}
                            type="checkbox"
                            name={"json.apresentation.configs.media"}
                            onChange={(e) => {
                                setFieldValue(
                                    'json.apresentation.configs.media', 'image-ai'
                                );
                                setFieldValue(
                                    'json.apresentation.configs.leonardoai.status', true
                                );
                            }}
                            label={`Imagem (Leonardo AI)`}
                            isInvalid={!!errors.json?.apresentation?.configs?.media}
                            feedback={errors.json?.apresentation?.configs?.media}
                            feedbackType="invalid">
                        </Form.Check>
                    </Form.Group>
                    <Form.Group sm={6} className='mb-3 ms-3'>
                        <Form.Check
                            className="mode"
                            disabled
                            type="checkbox"
                            name={"json.apresentation.configs.media"}
                            label={`Vídeo`}
                            isInvalid={!!errors.json?.apresentation?.configs?.media}
                            feedback={errors.json?.apresentation?.configs?.media}
                            feedbackType="invalid">
                        </Form.Check>
                    </Form.Group>
                </div>

                {values.json.media === 'video' && <Row className="text-start">
                    <Form.Group as={Col} sm={6} className='mb-3'>
                        <Form.Label>Duração do vídeo (segundos)</Form.Label>
                        <Form.Control
                            type="number"
                            name="json.apresentation.configs.videoDuration"
                            value={values.json.apresentation.configs.videoDuration}
                            onChange={(e) => {
                                handleChange(e);
                            }}
                            isInvalid={!!errors.json?.apresentation?.configs?.videoDuration}
                        />
                        <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid">
                            {errors.json?.apresentation?.configs?.videoDuration}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Row>}
                {values.json.apresentation.configs.media === 'image-ai' && <Row className="text-start mx-0 mb-3">
                    <Form.Group as={Col} sm={12}>
                        <Form.Label>Modelo</Form.Label>
                        <Select
                            name="json.apresentation.configs.leonardoai.modelId"
                            className="mb-3"
                            options={leonardoaiPlataforms}
                            components={{
                                Option: customOption,
                            }}
                            value={leonardoaiPlataforms.find(
                                (option) => option.value === values.json.apresentation.configs.leonardoai.modelId
                            )}
                            onChange={(selectedOption) => {
                                setFieldValue(
                                    'json.apresentation.configs.leonardoai.modelId', selectedOption.value
                                );
                            }}
                            styles={{
                                control: (provided) => ({
                                    ...provided,
                                    padding: 5,
                                }),
                            }}
                        />
                    </Form.Group>
                    <Form.Group
                        className="mb-3"
                        as={Col}
                        sm="12"
                        controlId="validationFormik01"
                    >
                        <Form.Label>Descreva um roteiro</Form.Label>
                        <Form.Control
                            as={'textarea'}
                            name="json.apresentation.configs.leonardoai.prompt"
                            value={values.json.apresentation.configs.leonardoai.prompt}
                            onChange={handleChange}
                            isInvalid={!!errors?.json?.apresentation?.configs?.leonardoai?.prompt}
                            isValid={touched?.json?.apresentation?.configs?.leonardoai?.prompt && !errors.json?.apresentation?.configs?.leonardoai?.prompt}
                        />
                        <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid">
                            {errors?.json?.apresentation?.configs?.leonardoai?.prompt}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group
                        className="mb-3"
                        as={Col}
                        sm="12"
                        controlId="validationFormik01"
                    >
                        <Form.Label>Descreva o que não ter na imagem</Form.Label>
                        <Form.Control
                            as={'textarea'}
                            name="json.apresentation.configs.leonardoai.negative_prompt"
                            value={values.json.apresentation.configs.leonardoai.negative_prompt}
                            onChange={handleChange}
                            isInvalid={!!errors?.json?.apresentation?.configs?.leonardoai?.negative_prompt}
                            isValid={touched?.json?.apresentation?.configs?.leonardoai?.negative_prompt && !errors.json?.apresentation?.configs?.leonardoai?.negative_prompt}
                        />
                        <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid">
                            {errors?.json?.apresentation?.configs?.leonardoai?.negative_prompt}
                        </Form.Control.Feedback>
                    </Form.Group>


                    <Form.Group
                        as={Col}
                        sm={4}
                        className="mb-3"
                        controlId="validationFormik01">
                        <Form.Label>Largura (px)</Form.Label>
                        <Form.Control
                            type="text"
                            name="json.apresentation.configs.leonardoai.width"
                            value={values.json.apresentation.configs.leonardoai.width}
                            onChange={handleChange}
                            isInvalid={!!errors.json?.apresentation?.configs?.leonardoai?.width}
                            isValid={touched.json?.apresentation?.configs?.leonardoai?.width && !errors.json?.apresentation?.configs?.leonardoai?.width}
                        />
                        <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid">
                            {errors.json?.apresentation?.configs?.leonardoai?.width}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group
                        as={Col}
                        sm={4}
                        className="mb-3"
                        controlId="validationFormik01">
                        <Form.Label>Altura (px)</Form.Label>
                        <Form.Control
                            type="text"
                            name="json.apresentation.configs.leonardoai.height"
                            value={values.json.apresentation.configs.leonardoai.height}
                            onChange={handleChange}
                            isInvalid={!!errors.json?.apresentation?.configs?.leonardoai?.height}
                            isValid={touched.json?.apresentation?.configs?.leonardoai?.height && !errors.json?.apresentation?.configs?.leonardoai?.height}
                        />
                        <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid">
                            {errors.json?.apresentation?.configs?.leonardoai?.height}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group
                        as={Col}
                        md={4}
                        className="mb-3"
                        controlId="validationFormik01">
                        <Form.Label>Preset Style</Form.Label>
                        <Form.Select
                            name="json.apresentation.configs.leonardoai.presetStyle"
                            value={values.json.apresentation.configs.leonardoai.presetStyle}
                            onChange={handleChange}
                            isInvalid={!!errors.json?.apresentation?.configs?.leonardoai?.presetStyle}
                            isValid={touched.json?.apresentation?.configs?.leonardoai?.presetStyle && !errors.json?.apresentation?.configs?.leonardoai?.presetStyle}>
                            {presetStyles.map((element, index) => {
                                return (
                                    <option key={"colField" + index} value={element.value}>
                                        {element.label}
                                    </option>
                                );
                            })}
                        </Form.Select>
                        <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid">
                            {errors.json?.apresentation?.configs?.leonardoai?.presetStyle}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group
                        as={Col}
                        md={4}
                        className="mb-3"
                        controlId="validationFormik01">
                        <Form.Label>Guidance scale</Form.Label>
                        <Form.Control
                            type="number"
                            name="json.apresentation.configs.leonardoai.guidance_scale"
                            step={1}
                            value={values.json.apresentation?.configs?.leonardoai?.guidance_scale}
                            onChange={handleChange}
                            isInvalid={!!errors.json?.apresentation?.configs?.leonardoai?.guidance_scale}
                            isValid={touched.json?.apresentation?.configs?.leonardoai?.guidance_scale && !errors.json?.apresentation?.configs?.leonardoai?.guidance_scale}
                        />
                        <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid">
                            {errors.json?.apresentation?.configs?.leonardoai?.guidance_scale}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group
                        as={Col}
                        md={4}
                        className="mb-3"
                        controlId="validationFormik01">
                        <Form.Label>Quantidade de imagens geradas</Form.Label>
                        <Form.Control
                            type="number"
                            name="json.apresentation.configs.leonardoai.num_images"
                            step={"1"}
                            value={values.json.apresentation.configs.leonardoai.num_images}
                            onChange={handleChange}
                            isInvalid={!!errors.json?.apresentation?.configs?.leonardoai?.num_images}
                            isValid={touched.json?.apresentation?.configs?.leonardoai?.num_images && !errors.json?.apresentation?.configs?.leonardoai?.num_images}
                        />
                        <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid">
                            {errors.json?.apresentation?.configs?.leonardoai?.num_images}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group
                        as={Col}
                        sm="12"
                        controlId="validationFormik01"
                    >
                        <Form.Check
                            checked={values.json.apresentation.configs.leonardoai.photoReal ? true : false}
                            type="checkbox"
                            label="PhotoReal"
                            onChange={(e) => {
                                setFieldValue(
                                    "json.apresentation.configs.leonardoai.photoReal",
                                    values.json.apresentation.configs.leonardoai.photoReal ? false : true
                                );
                            }}
                        />
                        <Form.Check
                            checked={values.json.apresentation.configs.leonardoai.alchemy ? true : false}
                            type="checkbox"
                            label="Alchemy"
                            onChange={(e) => {
                                setFieldValue(
                                    "json.apresentation.configs.leonardoai.alchemy",
                                    values.json.apresentation.configs.leonardoai.alchemy ? false : true
                                );
                            }}
                        />
                        <Form.Check
                            checked={true}
                            disabled
                            type="checkbox"
                            label="Foto de referência"
                        />
                        <Row className="mb-3 mx-0">
                            <Form.Group
                                as={Col}
                                className="mb-3"
                                sm={"4"}
                                controlId="validationFormik03">
                                <Form.Label>Força</Form.Label>
                                <Form.Select
                                    name="json.apresentation.configs.leonardoai.characterReference.strengthType"
                                    value={values.json.apresentation.configs.leonardoai.characterReference.strengthType}
                                    onChange={handleChange}
                                    isInvalid={!!errors.json?.apresentation?.configs?.leonardoai?.characterReference?.strengthType}
                                    isValid={touched.json?.apresentation?.configs?.leonardoai?.characterReference?.strengthType && !errors.json?.apresentation?.configs?.leonardoai?.characterReference?.strengthType}>
                                    {['Low', 'Mid', 'High', 'Ultra', 'Max'].map((element, index) => {
                                        return (
                                            <option key={"colField" + index} value={element}>
                                                {element}
                                            </option>
                                        );
                                    })}
                                </Form.Select>
                                <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">
                                    {errors.json?.apresentation?.configs?.leonardoai?.characterReference?.strengthType}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                        <Form.Check
                            checked={values.json.apresentation.configs.leonardoai.imageReference.status ? true : false}
                            type="checkbox"
                            label="Imagem de referência"
                            onChange={(e) => {
                                setFieldValue(
                                    "json.apresentation.configs.leonardoai.imageReference.status",
                                    values.json.apresentation.configs.leonardoai.imageReference.status ? false : true
                                );
                            }}
                        />

                        {values.json.apresentation.configs.leonardoai.imageReference.status && <Row className="mb-3 mx-0">
                            <Form.Group
                                as={Col}
                                md="6"
                                className="mt-3">
                                <Card className="mb-0">
                                    <Card.Body>
                                        <Form.Control
                                            type="hidden"
                                            name="json.apresentation.configs.leonardoai.imageReference.url"
                                            value={values.json.apresentation.configs.leonardoai.imageReference.url}
                                            onChange={handleChange}
                                            isInvalid={!!errors?.json?.apresentation?.configs?.leonardoai?.imageReference?.url}
                                            isValid={touched?.json?.apresentation?.configs?.leonardoai?.imageReference?.url && !errors?.json?.apresentation?.configs?.leonardoai?.imageReference?.url}
                                        />
                                        <div className="imagesUpload">
                                            <img
                                                className={`h-100 img-thumbnail ${!!errors?.json?.apresentation?.configs?.leonardoai?.imageReference?.url ? "is-invalid" : ""}`}
                                                src={
                                                    values.json.apresentation.configs.leonardoai.imageReference.url !== "" && values.json.apresentation.configs.leonardoai.imageReference.url !== null
                                                        ? domainConfig.imageServer +
                                                        "/customizacao/" +
                                                        values.customizacao_id +
                                                        "/" +
                                                        values.json.apresentation.configs.leonardoai.imageReference.url
                                                        : domainConfig.imageServer + "/empty-background.png"
                                                }
                                                alt="Imagem footer aplicativo"
                                            />
                                            <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                                            <Form.Control.Feedback type="invalid">
                                                {errors?.json?.apresentation?.configs?.leonardoai?.imageReference?.url}
                                            </Form.Control.Feedback>
                                        </div>
                                        <DropzoneComponent
                                            className="mt-3 dropzoneComp"
                                            type="image"
                                            handlePreviewSuccess={handlePreviewSuccess}
                                            handlePreviewError={handlePreviewError}
                                            setFieldValue={setFieldValue}
                                            folder="customizacao"
                                            id={values.customizacao_id}
                                            leonardoai={true}
                                            setFieldID="json.apresentation.configs.leonardoai.imageReference.id"
                                            field="json.apresentation.configs.leonardoai.imageReference.url"
                                        />
                                    </Card.Body>
                                </Card>
                            </Form.Group>
                            <Form.Group
                                as={Col}
                                className="mt-3"
                                sm={"6"}
                                controlId="validationFormik03">
                                <Form.Label>Força</Form.Label>
                                <Form.Select
                                    name="json.apresentation.configs.leonardoai.imageReference.strengthType"
                                    value={values.json.apresentation.configs.leonardoai.imageReference.strengthType}
                                    onChange={handleChange}
                                    isInvalid={!!errors.json?.apresentation?.configs?.leonardoai?.imageReference?.strengthType}
                                    isValid={touched.json?.apresentation?.configs?.leonardoai?.imageReference?.strengthType && !errors.json?.apresentation?.configs?.leonardoai?.imageReference?.strengthType}>
                                    {['Low', 'Mid', 'High', 'Ultra', 'Max'].map((element, index) => {
                                        return (
                                            <option key={"colField" + index} value={element}>
                                                {element}
                                            </option>
                                        );
                                    })}
                                </Form.Select>
                                <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">
                                    {errors.json?.apresentation?.configs?.leonardoai?.imageReference?.strengthType}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Row>}

                        <Form.Check
                            checked={values.json.apresentation.configs.leonardoai.contentReference.status ? true : false}
                            type="checkbox"
                            label="Conteúdo de referência"
                            onChange={(e) => {
                                setFieldValue(
                                    "json.apresentation.configs.leonardoai.contentReference.status",
                                    values.json.apresentation.configs.leonardoai.contentReference.status ? false : true
                                );
                            }}
                        />

                        {values.json.apresentation.configs.leonardoai.contentReference.status &&
                            <Row className="mb-3 mx-0">
                                <Form.Group
                                    as={Col}
                                    md="6"
                                    className="mt-3">
                                    <Card className="mb-0">
                                        <Card.Body>
                                            <Form.Control
                                                type="hidden"
                                                name="json.apresentation.configs.leonardoai.contentReference.url"
                                                value={values.json.apresentation.configs.leonardoai.contentReference.url}
                                                onChange={handleChange}
                                                isInvalid={!!errors?.json?.apresentation?.configs?.leonardoai?.contentReference?.url}
                                                isValid={touched?.json?.apresentation?.configs?.leonardoai?.contentReference?.url && !errors?.json?.apresentation?.configs?.leonardoai?.contentReference?.url}
                                            />
                                            <div className="imagesUpload">
                                                <img
                                                    className={`h-100 img-thumbnail ${!!errors?.json?.apresentation?.configs?.leonardoai?.contentReference?.url ? "is-invalid" : ""}`}
                                                    src={
                                                        values.json.apresentation.configs.leonardoai.contentReference.url !== "" && values.json.apresentation.configs.leonardoai.contentReference.url !== null
                                                            ? domainConfig.imageServer +
                                                            "/customizacao/" +
                                                            values.customizacao_id +
                                                            "/" +
                                                            values.json.apresentation.configs.leonardoai.contentReference.url
                                                            : domainConfig.imageServer + "/empty-background.png"
                                                    }
                                                    alt="Imagem footer aplicativo"
                                                />
                                                <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                                                <Form.Control.Feedback type="invalid">
                                                    {errors?.json?.apresentation?.configs?.leonardoai?.contentReference?.url}
                                                </Form.Control.Feedback>
                                            </div>
                                            <DropzoneComponent
                                                className="mt-3 dropzoneComp"
                                                type="image"
                                                handlePreviewSuccess={handlePreviewSuccess}
                                                handlePreviewError={handlePreviewError}
                                                setFieldValue={setFieldValue}
                                                folder="customizacao"
                                                id={values.customizacao_id}
                                                leonardoai={true}
                                                setFieldID="json.apresentation.configs.leonardoai.contentReference.id"
                                                field="json.apresentation.configs.leonardoai.contentReference.url"
                                            />
                                        </Card.Body>
                                    </Card>
                                </Form.Group>
                                <Form.Group
                                    as={Col}
                                    className="mt-3"
                                    sm={"6"}
                                    controlId="validationFormik03">
                                    <Form.Label>Força</Form.Label>
                                    <Form.Select
                                        name="json.apresentation.configs.leonardoai.contentReference.strengthType"
                                        value={values.json.apresentation.configs.leonardoai.contentReference.strengthType}
                                        onChange={handleChange}
                                        isInvalid={!!errors.json?.apresentation?.configs?.leonardoai?.contentReference?.strengthType}
                                        isValid={touched.json?.apresentation?.configs?.leonardoai?.contentReference?.strengthType && !errors.json?.apresentation?.configs?.leonardoai?.contentReference?.strengthType}>
                                        {['Low', 'Mid', 'High', 'Ultra', 'Max'].map((element, index) => {
                                            return (
                                                <option key={"colField" + index} value={element}>
                                                    {element}
                                                </option>
                                            );
                                        })}
                                    </Form.Select>
                                    <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid">
                                        {errors.json?.apresentation?.configs?.leonardoai?.contentReference?.strengthType}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Row>}
                        <Form.Check
                            checked={values.json.apresentation.configs.leonardoai.poseReference.status ? true : false}
                            type="checkbox"
                            label="Pose de referência"
                            onChange={(e) => {
                                setFieldValue(
                                    "json.apresentation.configs.leonardoai.poseReference.status",
                                    values.json.apresentation.configs.leonardoai.poseReference.status ? false : true
                                );
                            }}
                        />

                        {values.json.apresentation.configs.leonardoai.poseReference.status &&
                            <Row className="mb-3 mx-0">
                                <Form.Group
                                    as={Col}
                                    md="6"
                                    className="mt-3">
                                    <Card className="mb-0">
                                        <Card.Body>
                                            <Form.Control
                                                type="hidden"
                                                name="json.apresentation.configs.leonardoai.poseReference.url"
                                                value={values.json.apresentation.configs.leonardoai.poseReference.url}
                                                onChange={handleChange}
                                                isInvalid={!!errors?.json?.apresentation?.configs?.leonardoai?.poseReference?.url}
                                                isValid={touched?.json?.apresentation?.configs?.leonardoai?.poseReference?.url && !errors?.json?.apresentation?.configs?.leonardoai?.poseReference?.url}
                                            />
                                            <div className="imagesUpload">
                                                <img
                                                    className={`h-100 img-thumbnail ${!!errors?.json?.apresentation?.configs?.leonardoai?.poseReference?.url ? "is-invalid" : ""}`}
                                                    src={
                                                        values.json.apresentation.configs.leonardoai.poseReference.url !== "" && values.json.apresentation.configs.leonardoai.poseReference.url !== null
                                                            ? domainConfig.imageServer +
                                                            "/customizacao/" +
                                                            values.customizacao_id +
                                                            "/" +
                                                            values.json.apresentation.configs.leonardoai.poseReference.url
                                                            : domainConfig.imageServer + "/empty-background.png"
                                                    }
                                                    alt="Imagem footer aplicativo"
                                                />
                                                <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                                                <Form.Control.Feedback type="invalid">
                                                    {errors?.json?.apresentation?.configs?.leonardoai?.poseReference?.url}
                                                </Form.Control.Feedback>
                                            </div>
                                            <DropzoneComponent
                                                className="mt-3 dropzoneComp"
                                                type="image"
                                                handlePreviewSuccess={handlePreviewSuccess}
                                                handlePreviewError={handlePreviewError}
                                                setFieldValue={setFieldValue}
                                                folder="customizacao"
                                                id={values.customizacao_id}
                                                leonardoai={true}
                                                setFieldID="json.apresentation.configs.leonardoai.poseReference.id"
                                                field="json.apresentation.configs.leonardoai.poseReference.url"
                                            />
                                        </Card.Body>
                                    </Card>
                                </Form.Group>
                                <Form.Group
                                    as={Col}
                                    className="mt-3"
                                    sm={"6"}
                                    controlId="validationFormik03">
                                    <Form.Label>Força</Form.Label>
                                    <Form.Select
                                        name="json.apresentation.configs.leonardoai.poseReference.strengthType"
                                        value={values.json.apresentation.configs.leonardoai.poseReference.strengthType}
                                        onChange={handleChange}
                                        isInvalid={!!errors.json?.apresentation?.configs?.leonardoai?.poseReference?.strengthType}
                                        isValid={touched.json?.apresentation?.configs?.leonardoai?.poseReference?.strengthType && !errors.json?.apresentation?.configs?.leonardoai?.poseReference?.strengthType}>
                                        {['Low', 'Mid', 'High', 'Ultra', 'Max'].map((element, index) => {
                                            return (
                                                <option key={"colField" + index} value={element}>
                                                    {element}
                                                </option>
                                            );
                                        })}
                                    </Form.Select>
                                    <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid">
                                        {errors.json?.apresentation?.configs?.leonardoai?.poseReference?.strengthType}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Row>}
                    </Form.Group>
                </Row>}
            </Row>
            <Form.Group as={Col} className="textHeaderAplicativo mb-3">
                <h5 className="card-title mb-0">
                    Participante
                </h5>
            </Form.Group>
            <Row className="mx-0 mb-3">
                <p className="text-start fw-bold mb-2">Tela de resultado</p>
                <Form.Group
                    className="mb-3"
                    as={Col}
                    sm="12"
                    controlId="validationFormik01"
                >
                    <Form.Label>Título</Form.Label>
                    <Form.Control
                        as={'textarea'}
                        name="json.result.title"
                        value={values.json.result.title}
                        onChange={handleChange}
                        isInvalid={!!errors?.json?.result?.title}
                        isValid={touched?.json?.result?.title && !errors.json?.result?.title}
                    />
                    <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        {errors?.json?.result?.title}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group
                    className="mb-3"
                    as={Col}
                    sm="12"
                    controlId="validationFormik01"
                >
                    <Form.Label>Descrição</Form.Label>
                    <Form.Control
                        as={'textarea'}
                        name="json.result.description"
                        value={values.json.result.description}
                        onChange={handleChange}
                        isInvalid={!!errors?.json?.result?.description}
                        isValid={touched?.json?.result?.description && !errors.json?.result?.description}
                    />
                    <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        {errors?.json?.result?.description}
                    </Form.Control.Feedback>
                </Form.Group>
            </Row>
            <Form.Group as={Col} className="textHeaderAplicativo mb-3">
                <h5 className="card-title mb-0">
                    Modo de apresentação
                </h5>
            </Form.Group>
            <Row className="mx-0">
                <Form.Group
                    as={Col}
                    md={4}
                    className="mb-3"
                    controlId="validationFormik01">
                    <Form.Label>Tamanho fonte (M) (rem)</Form.Label>
                    <Form.Control
                        type="number"
                        name="json.apresentation.textSide.h5"
                        step={"0.01"}
                        value={values.json.apresentation?.textSide?.h5}
                        onChange={handleChange}
                        isInvalid={!!errors.json?.apresentation?.textSide?.h5}
                        isValid={touched.json?.apresentation?.textSide?.h5 && !errors.json?.apresentation?.textSide?.h5}
                    />
                    <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        {errors.json?.apresentation?.textSide?.h5}
                    </Form.Control.Feedback>
                </Form.Group>

                <Form.Group
                    as={Col}
                    md={4}
                    className="mb-3"
                    controlId="validationFormik01">
                    <Form.Label>Tamanho fonte (P) (rem)</Form.Label>
                    <Form.Control
                        type="number"
                        name="json.apresentation.textSide.p"
                        step={"0.01"}
                        value={values.json.apresentation.textSide.p}
                        onChange={handleChange}
                        isInvalid={!!errors.json?.apresentation?.textSide?.p}
                        isValid={touched.json?.apresentation?.textSide?.p && !errors.json?.apresentation?.textSide?.p}
                    />
                    <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        {errors.json?.apresentation?.textSide?.p}
                    </Form.Control.Feedback>
                </Form.Group>
                <div className="col-md-4 mb-3">
                    <Form.Label>Cor de fundo e texto</Form.Label>
                    <div className={`d-flex justify-content-start align-items-center ${errors?.json?.apresentation?.textSide?.textColor || errors?.json?.apresentation?.textSide?.backgroundColor ? 'is-invalid' : ''}`}>
                        <Form.Control
                            type="hidden"
                            name="json.apresentation.textSide.backgroundColor"
                            value={values.json?.apresentation?.textSide?.backgroundColor}
                            isInvalid={!!errors?.json?.apresentation?.textSide?.backgroundColor}
                            isValid={touched?.json?.apresentation?.textSide?.backgroundColor && !errors?.json?.apresentation?.textSide?.backgroundColor}
                        />
                        <Form.Control
                            type="hidden"
                            name="json.apresentation.textSide.textColor"
                            value={values.json?.apresentation?.textSide?.textColor}
                            isInvalid={!!errors?.json?.apresentation?.textSide?.textColor}
                            isValid={touched?.json?.apresentation?.textSide?.textColor && !errors?.json?.apresentation?.textSide?.textColor}
                        />
                        <PopoverPicker
                            color={props.backgroundColor}
                            onChangeX={(e) => {
                                setFieldValue("json.apresentation.textSide.backgroundColor", e);
                                setProps(prevState => ({
                                    ...prevState,
                                    backgroundColor: e
                                }));
                            }}
                            classes={`me-1 picker ${errors?.json?.apresentation?.textSide?.backgroundColor ? 'is-invalid' : ''}`}
                        />
                        <PopoverPicker
                            color={props.textColor}
                            onChangeX={(e) => {
                                setFieldValue("json.apresentation.textSide.textColor", e);
                                setProps(prevState => ({
                                    ...prevState,
                                    textColor: e
                                }));
                            }}
                            classes={`me-1 picker ${errors?.json?.apresentation?.textSide?.textColor ? 'is-invalid' : ''}`}
                        />
                    </div>
                    <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        {errors?.json?.apresentation?.textSide?.textColor ? errors?.json?.apresentation?.textSide?.textColor : errors?.json?.apresentation?.textSide?.backgroundColor ? errors?.json?.apresentation?.textSide?.backgroundColor : ''}
                    </Form.Control.Feedback>
                </div>
            </Row>
            <Row className="mx-0 mb-3">
                <p className="text-start fw-bold mt-3 mb-2">Logomarca</p>
                <Form.Group as={Col} sm={6} className='mb-3'>
                    <Form.Label>Largura (px)</Form.Label>
                    <Form.Control
                        type="number"
                        name="json.apresentation.configs.logoSize"
                        value={values.json.apresentation.configs.logoSize}
                        onChange={(e) => {
                            handleChange(e);
                        }}
                        isInvalid={!!errors.json?.apresentation?.configs?.logoSize}
                    />
                    <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        {errors.json?.apresentation?.configs?.logoSize}
                    </Form.Control.Feedback>
                </Form.Group>
            </Row>
            <Row className="mx-0 mb-3">
                <p className="text-start fw-bold mb-2">Tela inicial</p>
                <Form.Group
                    as={Col} sm={8} className='mb-3'
                    controlId="validationFormik01">
                    <Form.Label>Botão</Form.Label>
                    <Form.Control
                        type="text"
                        name="json.apresentation.firstPage.buttonText"
                        value={values.json.apresentation.firstPage.buttonText}
                        onChange={handleChange}
                        isInvalid={!!errors?.json?.apresentation?.firstPage?.buttonText}
                        isValid={touched?.json?.apresentation?.firstPage?.buttonText && !errors.json?.apresentation?.firstPage?.buttonText}
                    />
                    <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        {errors?.json?.apresentation?.firstPage?.buttonText}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} sm={4} className='mb-3'>
                    <Form.Label>Posição (%)</Form.Label>
                    <Form.Control
                        type="number"
                        name="json.apresentation.configs.buttonPosition"
                        value={values.json.apresentation.configs.buttonPosition}
                        onChange={(e) => {
                            handleChange(e);
                        }}
                        isInvalid={!!errors.json?.apresentation?.configs?.buttonPosition}
                    />
                    <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        {errors.json?.apresentation?.configs?.buttonPosition}
                    </Form.Control.Feedback>
                </Form.Group>
            </Row>
            <Row className="mx-0 mb-3">
                <p className="text-start fw-bold mb-2">Tela preview</p>
                <Form.Group as={Col} sm={4} className='mb-3'>
                    <Form.Label>Posição (%)</Form.Label>
                    <Form.Control
                        type="number"
                        name="json.apresentation.configs.buttonPositionPreview"
                        value={values.json.apresentation.configs.buttonPositionPreview}
                        onChange={(e) => {
                            handleChange(e);
                        }}
                        isInvalid={!!errors.json?.apresentation?.configs?.buttonPositionPreview}
                    />
                    <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        {errors.json?.apresentation?.configs?.buttonPositionPreview}
                    </Form.Control.Feedback>
                </Form.Group>
            </Row>
            <Row className="mx-0 mb-3">
                <p className="text-start fw-bold mb-2">Tela do formulário</p>
                <Form.Group
                    className="mb-3"
                    as={Col}
                    sm="12"
                    controlId="validationFormik01"
                >
                    <Form.Label>Título</Form.Label>
                    <Form.Control
                        as={'textarea'}
                        name="json.apresentation.register.title"
                        value={values.json?.apresentation?.register?.title}
                        onChange={handleChange}
                        isInvalid={!!errors?.json?.apresentation?.register?.title}
                        isValid={touched?.json?.apresentation?.register?.title && !errors.json?.apresentation?.register?.title}
                    />
                    <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        {errors?.json?.apresentation?.register?.title}
                    </Form.Control.Feedback>
                </Form.Group>
            </Row>
            <Row className="mx-0 mb-3">
                <p className="text-start fw-bold mb-2">Tela do resultado</p>
                <Form.Group
                    className="mb-3"
                    as={Col}
                    sm="12"
                    controlId="validationFormik01"
                >
                    <Form.Label>Título</Form.Label>
                    <Form.Control
                        as={'textarea'}
                        name="json.apresentation.endPage.title"
                        value={values.json.apresentation.endPage.title}
                        onChange={handleChange}
                        isInvalid={!!errors?.json?.apresentation?.endPage?.title}
                        isValid={touched?.json?.apresentation?.endPage?.title && !errors.json?.apresentation?.endPage?.title}
                    />
                    <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        {errors?.json?.apresentation?.endPage?.buttonText}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group
                    className="mb-3"
                    as={Col}
                    sm="12"
                    controlId="validationFormik01"
                >
                    <Form.Label>Descrição</Form.Label>
                    <Form.Control
                        as={'textarea'}
                        name="json.apresentation.endPage.description"
                        value={values.json.apresentation.endPage.description}
                        onChange={handleChange}
                        isInvalid={!!errors?.json?.apresentation?.endPage?.description}
                        isValid={touched?.json?.apresentation?.endPage?.description && !errors.json?.apresentation?.endPage?.description}
                    />
                    <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        {errors?.json?.apresentation?.endPage?.description}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group
                    className="mb-3"
                    as={Col}
                    sm="8"
                    controlId="validationFormik01"
                >
                    <Form.Label>Botão</Form.Label>
                    <Form.Control
                        type="text"
                        name="json.apresentation.endPage.buttonText"
                        value={values.json.apresentation.endPage.buttonText}
                        onChange={handleChange}
                        isInvalid={!!errors?.json?.apresentation?.endPage?.buttonText}
                        isValid={touched?.json?.apresentation?.endPage?.buttonText && !errors.json?.apresentation?.endPage?.buttonText}
                    />
                    <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        {errors?.json?.apresentation?.endPage?.buttonText}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} sm={4} className='mb-3'>
                    <Form.Label>Posição (%)</Form.Label>
                    <Form.Control
                        type="number"
                        name="json.apresentation.configs.buttonPositionEnd"
                        value={values.json.apresentation.configs.buttonPositionEnd}
                        onChange={(e) => {
                            handleChange(e);
                        }}
                        isInvalid={!!errors.json?.apresentation?.configs?.buttonPositionEnd}
                    />
                    <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        {errors.json?.apresentation?.configs?.buttonPositionEnd}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} sm={6} className='mb-3'>
                    <Form.Label>Finalizar fluxo automaticamente (milesimos)</Form.Label>
                    <Form.Control
                        type="number"
                        step={1000}
                        name="json.apresentation.configs.qrcodeEndPage"
                        value={values.json.apresentation.configs.qrcodeEndPage}
                        onChange={(e) => {
                            handleChange(e);
                        }}
                        isInvalid={!!errors.json?.apresentation?.configs?.qrcodeEndPage}
                    />
                    <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        {errors.json?.apresentation?.configs?.qrcodeEndPage}
                    </Form.Control.Feedback>
                </Form.Group>

                <p className="text-start fw-bold mt-3 mb-2">Galeria</p>
                <Form.Group as={Col} sm={6} className='mb-3'>
                    <Form.Label>Tempo entre slides (milesimos)</Form.Label>
                    <Form.Control
                        type="number"
                        step={1000}
                        name="json.apresentation.configs.galleryTransition"
                        value={values.json.apresentation.configs.galleryTransition}
                        onChange={(e) => {
                            handleChange(e);
                        }}
                        isInvalid={!!errors.json?.apresentation?.configs?.galleryTransition}
                    />
                    <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        {errors.json?.apresentation?.configs?.galleryTransition}
                    </Form.Control.Feedback>
                </Form.Group>
            </Row>
            {(values.json.apresentation.configs.media === 'image' || values.json.apresentation.configs.media === 'image-ai') &&
                <>
                    <Form.Group as={Col} className="textHeaderAplicativo mb-3">
                        <h5 className="card-title mb-0">
                            Media <span className="small">(Tamanho máximo: 10mb)</span>
                        </h5>
                    </Form.Group>
                    <Row className="mx-0">
                        <Form.Group
                            as={Col}
                            md="6">
                            <Card className=" mb-3">
                                <Card.Body>
                                    <div className="text-center mb-3">
                                        <div className="d-flex justify-content-center mb-3">
                                            <Form.Label>Frame vertical</Form.Label>
                                        </div>
                                    </div>
                                    <Form.Control
                                        type="hidden"
                                        name="json.apresentation.frame"
                                        value={values.json.apresentation.frame}
                                        onChange={handleChange}
                                        isInvalid={!!errors?.json?.apresentation?.frame}
                                        isValid={touched?.json?.apresentation?.frame && !errors?.json?.apresentation?.frame}
                                    />
                                    <div className="imagesUpload">
                                        <img
                                            className={`h-100 img-thumbnail ${!!errors?.json?.apresentation?.frame ? "is-invalid" : ""}`}
                                            src={
                                                values.json.apresentation.frame !== "" && values.json.apresentation.frame !== null
                                                    ? domainConfig.imageServer +
                                                    "/customizacao/" +
                                                    values.customizacao_id +
                                                    "/" +
                                                    values.json.apresentation.frame
                                                    : domainConfig.imageServer + "/empty-background.png"
                                            }
                                            alt="Imagem footer aplicativo"
                                        />
                                        <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                                        <Form.Control.Feedback type="invalid">
                                            {errors?.json?.apresentation?.frame}
                                        </Form.Control.Feedback>
                                    </div>
                                    <DropzoneComponent
                                        className="mt-3 dropzoneComp"
                                        type="image"
                                        handlePreviewSuccess={handlePreviewSuccess}
                                        handlePreviewError={handlePreviewError}
                                        setFieldValue={setFieldValue}
                                        folder="customizacao"
                                        id={values.customizacao_id}
                                        field="json.apresentation.frame"
                                    />
                                </Card.Body>
                            </Card>
                        </Form.Group>
                        <Form.Group
                            as={Col}
                            md="6">
                            <Card className=" mb-3">
                                <Card.Body>
                                    <div className="d-flex justify-content-center mb-3">
                                        <Form.Label>Frame horizontal</Form.Label>
                                    </div>
                                    <Form.Control
                                        type="hidden"
                                        name="json.apresentation.frameHorizontal"
                                        value={values.json.apresentation?.frameHorizontal}
                                        onChange={handleChange}
                                        isInvalid={!!errors?.json?.apresentation?.frameHorizontal}
                                        isValid={touched?.json?.apresentation?.frameHorizontal && !errors?.json?.apresentation?.frameHorizontal}
                                    />
                                    <div className="imagesUpload">
                                        <img
                                            className={`h-100 img-thumbnail ${!!errors?.json?.apresentation?.frameHorizontal ? "is-invalid" : ""}`}
                                            src={
                                                values.json.apresentation?.frameHorizontal !== "" && values.json.apresentation?.frameHorizontal !== null
                                                    ? domainConfig.imageServer +
                                                    "/customizacao/" +
                                                    values.customizacao_id +
                                                    "/" +
                                                    values.json.apresentation.frameHorizontal
                                                    : domainConfig.imageServer + "/empty-background.png"
                                            }
                                            alt="Imagem footer aplicativo"
                                        />
                                        <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                                        <Form.Control.Feedback type="invalid">
                                            {errors?.json?.apresentation?.frameHorizontal}
                                        </Form.Control.Feedback>
                                    </div>
                                    <DropzoneComponent
                                        className="mt-3 dropzoneComp"
                                        type="image"
                                        handlePreviewSuccess={handlePreviewSuccess}
                                        handlePreviewError={handlePreviewError}
                                        setFieldValue={setFieldValue}
                                        folder="customizacao"
                                        id={values.customizacao_id}
                                        field="json.apresentation.frameHorizontal"
                                    />
                                </Card.Body>
                            </Card>
                        </Form.Group>
                    </Row>
                </>}
        </>
    );
};

export default Photobooth